import { gql } from '@apollo/client';

export const CoursesQuery = gql`
  query AllCourses($active: Boolean, $finished: Boolean, $first: Int, $after: String, $last: Int) {
    allGroupsForLms(
      active: $active
      finished: $finished
      first: $first
      after: $after
      last: $last
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... on GroupType {
            id
            course {
              id
              courseIcon
              educationForm
              name
            }
            passedLessonsCount
            lessonsCount
            studentsCount
            name
            statusFinished
            datetimeNextLesson
          }
          ... on StudentGroupType {
            id
            course {
              id
              courseIcon
              educationForm
              name
            }
            passedLessonsCount
            lessonsCount
            studentsCount
            name
            statusFinished
            datetimeNextLesson
          }
          ... on MethodistGroupType {
            id
            course {
              id
              courseIcon
              educationForm
              name
            }
            passedLessonsCount
            lessonsCount
            studentsCount
            name
            statusFinished
            datetimeNextLesson
          }
        }
      }
    }
  }
`;

export const CourseQuery = gql`
  query groupForLms($groupId: ID) {
    groupForLms(groupId: $groupId) {
      ... on GroupType {
        id
        course {
          id
          courseIcon
          name
        }
        statusFinished
        isFree
        lessons {
          id
          name
          date
          index
          needToFinish
        }
        passedLessonsCount
        lessonsCount
        studentsCount
        name
      }
      ... on StudentGroupType {
        id
        course {
          id
          courseIcon
          name
        }
        statusFinished
        isFree
        lessons {
          id
          name
          date
          index
        }
        passedLessonsCount
        lessonsCount
        studentsCount
        name
      }
      ... on MethodistGroupType {
        id
        course {
          id
          courseIcon
          name
        }
        statusFinished
        isFree
        lessons {
          id
          name
          date
          index
        }
        passedLessonsCount
        lessonsCount
        studentsCount
        name
      }
    }
  }
`;
