import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import fontService, { FontWeight, TextType } from 'services/FontService';
import { desktop, tablet } from 'services/ui';

import { Colors } from 'context';
import { ProgramLessonType } from 'components/types';
import { useReactiveVar } from '@apollo/client';
import { authState } from 'core';

export interface ICourseInfo {
  courseName: string;
  groupName: string;
  studentsCount: number;
  passedLessonsCount: number;
  courseImageURL: string;
  lessons: ProgramLessonType[];
  lessonsCount: number;
  isFinished?: boolean | null;
  isFree?: boolean | null;
}

export const CourseInfo = memo(
  ({
    courseImageURL,
    courseName,
    groupName,
    studentsCount,
    passedLessonsCount,
    lessonsCount,
  }: ICourseInfo) => {
    const { t } = useTranslation();
    const { isDemoUser } = useReactiveVar(authState);
    return (
      <Wrapper>
        <ImageWrapper>
          <CourseImage src={courseImageURL} alt="" />
        </ImageWrapper>
        <CourseInfoWrapper>
          <CourseName>{courseName}</CourseName>
          <InfoContainer>
            <Info>
              {t('courseInfo.group')} <InfoValue>{groupName}</InfoValue>
            </Info>
            <Info>
              {t('courseInfo.amount')}
              <InfoValue>
                {isDemoUser ? 15 : studentsCount} {t('courseInfo.attendance')}
              </InfoValue>
            </Info>
            <Info>
              {t('courseInfo.lessons')}{' '}
              <InfoValue>
                {passedLessonsCount}/{lessonsCount}
              </InfoValue>
            </Info>
          </InfoContainer>
        </CourseInfoWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;

  ${tablet} {
    flex-direction: row;
  }

  ${desktop} {
    flex-direction: column;
    margin: 0;
  }
`;

const ImageWrapper = styled.div`
  display: none;

  ${tablet} {
    margin-left: auto;
    display: block;
    overflow: hidden;
    width: 240px;
    height: 104px;
    border-bottom: 1px solid ${Colors.system_gray};
    order: 2;
  }

  ${desktop} {
    margin: 0;
    border-bottom: 1px solid ${Colors.bg_gray};
    order: 1;
  }
`;

const CourseImage = styled.img`
  width: 240px;
  height: 165px;
  object-fit: contain;
  object-position: center;
`;

const CourseInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  order: 1;

  ${desktop} {
    order: 2;
  }
`;

const CourseName = styled.h3`
  ${fontService.getFont({ size: 18, lineHeight: 133, weight: FontWeight.bold })}
  margin: 0 0 12px;
  max-width: 300px;

  ${tablet} {
    ${fontService.h3()}
    margin: 0 0 8px;
    max-width: 400px;
  }

  ${desktop} {
    margin: 24px 0 8px 0;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: auto;
  flex-wrap: wrap;
  row-gap: 8px;

  ${tablet} {
    flex-wrap: nowrap;
    gap: 0;
  }

  ${desktop} {
    margin: 0;
  }
`;

const Info = styled.div`
  margin-right: 24px;
  ${fontService.text({ type: TextType.extraSmall })};

  &:last-child {
    margin: 0;
  }
`;

const InfoValue = styled.div`
  ${fontService.text({ type: TextType.normal, weight: FontWeight.bold })};
`;
