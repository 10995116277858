import React, { memo, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useCourse, useGoToErrorPage } from 'core';
import { CourseProgram } from 'molecules';
import { PATHS } from 'router/config';
import { Colors } from 'context';
import { JournalPreloader, ProgramPreloader } from 'components/atoms';
import { Lesson } from 'organisms';
import { desktop, tablet } from 'services/ui';

export const Course = memo(() => {
  const navigate = useNavigate();
  const { groupId, lessonId } = useParams();
  const { courseData, loading, error } = useCourse(groupId || '');
  const goToErrorPage = useGoToErrorPage();

  const activeLessonId: string | undefined = useMemo(() => {
    if (!courseData) {
      return;
    }

    if (courseData.isFree) {
      return courseData.lessons[0].id;
    }

    const { lessons, lessonsCount, isFinished } = courseData;

    if (isFinished) {
      return lessons[lessonsCount - 1]?.id;
    }

    const activeLesson = lessons?.find((programLesson) => programLesson.isActive);

    return activeLesson?.id;
  }, [courseData]);

  const [currentLessonId, setCurrentLessonId] = useState(activeLessonId);

  useEffect(() => {
    if (lessonId) {
      setCurrentLessonId(lessonId);
    }
  }, [lessonId]);

  const hasLessonsToFinish = useMemo(
    () => Boolean(courseData?.lessons.find((programLesson) => programLesson.needToFinish)),
    [courseData?.lessons],
  );

  useEffect(() => {
    if (error) {
      console.log({ courseError: error });
      goToErrorPage();
    }
  }, [error, goToErrorPage]);

  useEffect(() => {
    if (!lessonId && activeLessonId) {
      navigate(`/courses/${groupId}/${activeLessonId}/${PATHS.lessonDescription}`, {
        replace: true,
      });
    }
  }, [activeLessonId, groupId, lessonId, navigate]);

  return (
    <CourseContainer>
      {!loading && courseData ? (
        <CourseProgram {...courseData} activeLessonID={activeLessonId} />
      ) : (
        <ProgramPreloader />
      )}

      <LessonContainer>
        {loading && <JournalPreloader />}
        {currentLessonId && (
          <Lesson
            groupId={groupId}
            currentLessonId={currentLessonId}
            hasLessonsToFinish={hasLessonsToFinish}
          />
        )}
      </LessonContainer>
    </CourseContainer>
  );
});

const CourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  ${tablet} {
    margin-top: 40px;
  }

  ${desktop} {
    flex-direction: row;
    margin-top: 43px;
  }
`;

const LessonContainer = styled.div`
  margin: 0 -20px 80px;
  position: relative;
  flex-grow: 1;
  padding: 24px 20px 56px;
  border: 1px solid ${Colors.system_gray};
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(100, 100, 100, 0.4);
  overflow: hidden;
  box-sizing: border-box;

  ${tablet} {
    margin: 0 0 80px;
    padding: 40px 32px 80px;
    border-radius: 24px;
  }

  ${desktop} {
    margin: 0 0 80px 64px;
    margin-right: calc((1125px - 100vw) / 2);
    padding: 40px 60px;
    border-right: 0;
    border-radius: 24px 0 0 24px;
    min-height: 970px;
  }
`;
