import React, { memo, useCallback, useReducer, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';

import { SignUpForm } from 'components/organisms';
import { ISignUpFormValues, ISignUpErrorValues } from 'organisms/SingUpForm/SignUpForm';

import { STATE_ACTIONS_TYPE } from 'context';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'router/config';
import { useNavigate } from 'react-router-dom';

const REGISTER_STUDENT = gql`
  mutation RegisterUser(
    $firstName: String!
    $phone: String!
    $email: String!
    $recaptchaToken: String!
  ) {
    lmsStudentSignUp(
      studentInput: {
        firstName: $firstName
        phone: $phone
        email: $email
        recaptchaToken: $recaptchaToken
      }
    ) {
      success
      errors
      customErrors {
        code
        field
        message
      }
      student {
        user {
          firstName
          email
          phone
        }
      }
    }
  }
`;

const useSignUpMutation = () => useMutation(REGISTER_STUDENT);

const stateReducer = (
  state: ISignUpFormValues,
  action: { type: STATE_ACTIONS_TYPE; payload?: string },
) => {
  switch (action.type) {
    case STATE_ACTIONS_TYPE.changeFirstName:
      return { ...state, firstName: action.payload || '' };
    case STATE_ACTIONS_TYPE.changePhone:
      return { ...state, phone: action.payload || '' };
    case STATE_ACTIONS_TYPE.changeEmail:
      return { ...state, email: action.payload || '' };
    default:
      throw new Error();
  }
};

export const SignUp = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(stateReducer, {
    firstName: '',
    phone: '',
    email: '',
  });

  const [errorMessages, setErrorMessages] = useState<ISignUpErrorValues | null>(null);

  const [refreshRecaptcha, setRefreshRecaptcha] = useState<boolean>(false);

  const [registerMutation, { loading: loadingRegister }] = useSignUpMutation();

  const onFirstNameChange = useCallback((event) => {
    dispatch({ type: STATE_ACTIONS_TYPE.changeFirstName, payload: event.target.value });
  }, []);

  const onPhoneChange = useCallback((event) => {
    const { value } = event.target;

    if (value.length === 0) {
      dispatch({ type: STATE_ACTIONS_TYPE.changePhone, payload: '' });
      return;
    }

    if (!/^(\+?\d*)$/.test(value)) {
      return;
    }

    const validPhone = value[0] === '+' ? value : `+${value}`;

    dispatch({ type: STATE_ACTIONS_TYPE.changePhone, payload: validPhone });
  }, []);

  const onEmailChange = useCallback((event) => {
    dispatch({ type: STATE_ACTIONS_TYPE.changeEmail, payload: event.target.value });
  }, []);

  const onRegisterClick = useCallback(
    (recaptchaToken: string) => {
      setErrorMessages(null);
      setRefreshRecaptcha(false);

      registerMutation({
        variables: {
          firstName: state.firstName,
          phone: state.phone,
          email: state.email,
          recaptchaToken,
        },
        onCompleted: (data) => {
          const dataRegister = data.lmsStudentSignUp;

          if (dataRegister.success) {
            navigate(PATHS.login);
            return;
          }

          if (!dataRegister.success) {
            setRefreshRecaptcha(true);
          }

          if (!dataRegister.success && dataRegister.customErrors.length) {
            const customErrorsObj = dataRegister.customErrors
              .map(({ code, field }: any) => ({
                [field]: code,
              }))
              .reduce((result: any, error: any) => ({ ...result, ...error }), {});

            const errorMessagesObj = Object.keys(customErrorsObj).reduce(
              (massages: any, errorKey: any) => {
                massages[errorKey] = `${t(
                  `login.validation.${errorKey}.${customErrorsObj[errorKey]}`,
                )}`;
                return massages;
              },
              {},
            );

            setErrorMessages(errorMessagesObj);
            return;
          }

          if (!dataRegister.success) {
            setErrorMessages({
              general: `${t('error.error.heading')}`,
            });
          }
        },
        onError: () => {
          setRefreshRecaptcha(true);
          setErrorMessages({
            general: `${t('error.error.heading')}`,
          });
        },
      });
    },
    [state, registerMutation, navigate, t],
  );

  return (
    <SignUpContainer>
      <SignUpForm
        values={state}
        onFirstNameChange={onFirstNameChange}
        onPhoneChange={onPhoneChange}
        onEmailChange={onEmailChange}
        onRegisterClick={onRegisterClick}
        disableRegister={loadingRegister || !state.email || !state.firstName || !state.phone}
        isLoading={loadingRegister}
        errors={errorMessages}
        refreshRecaptcha={refreshRecaptcha}
      />
    </SignUpContainer>
  );
});

const SignUpContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0 100px 0;
  box-sizing: border-box;
`;
