export enum PATHS {
  signUp = '/sign-up',
  login = '/login',
  main = '/',
  courses = '/courses',
  course = '/courses/:groupId/*',
  lesson = '/courses/:groupId/:lessonId/*',
  lessonDescription = 'lessonDescription',
  lessonStudentAttachments = 'lessonAttachments/student',
  lessonTeacherAttachments = 'lessonAttachments/teacher',
  lessonJournal = 'lessonJournal',
  lessonTasks = 'lessonTasks',
  lessonResults = 'lessonResults',
  resume = '/resume',
  attachments = '/attachments',
  attachment = '/attachments/:type/:fileId/*',
  homework = 'homework',
  coursework = 'coursework',
  graduationProject = 'graduationProject',
}
