import React, { ChangeEvent, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { Icon, IconNames, LoadingIndicator } from 'atoms';
import { Colors } from 'context';
import { tablet, isLearnITTheme } from 'services/ui';
import fontService, { TextType } from 'services/FontService';
import { PATHS } from 'router/config';

import { EmailStep } from './EmailStep';
import { PassStep } from './PassStep';

export interface ILoginForm {
  values: ILoginFormValues;
  errors?: {
    [keys in keyof ILoginFormValues]?: string;
  };
  onEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onEmailSendClick: () => void;
  disableEmailSend: boolean;
  isEmailSent: boolean;
  onPassChange: (value: string) => void;
  onPassSendClick: () => void;
  onBackButtonClick: () => void;
  disablePassSend: boolean;
  isOutOfTime: boolean;
  isOutOfAttempts: boolean;
  isServiceUnavailable: boolean;
  isOpenInfo: boolean;
  onHelpButtonClick: () => void;
  onSupportClick: () => void;
  isDemoLoading: boolean;
}

interface ILoginFormValues {
  email: string;
  authenticationCode: string;
  time: string;
  waitingTimeForNextCode: number;
}

export const LoginFormComponent = memo(
  ({
    values,
    errors,
    onEmailChange,
    onEmailSendClick,
    disableEmailSend,
    isEmailSent,
    onPassChange,
    onPassSendClick,
    onBackButtonClick,
    disablePassSend,
    isOutOfTime = false,
    isOutOfAttempts = false,
    isServiceUnavailable = false,
    isOpenInfo,
    onHelpButtonClick,
    onSupportClick,
    isDemoLoading,
  }: ILoginForm) => {
    const navigate = useNavigate();

    const handleSignUp = () => {
      navigate(PATHS.signUp);
    };

    const { t } = useTranslation();
    return (
      <Wrapper>
        <LogoWrapper>
          <Logo name={IconNames.LogoVertical} color={Colors.white} />
        </LogoWrapper>

        <Content>
          {!isEmailSent ? (
            <EmailStep
              email={values.email}
              onEmailChange={onEmailChange}
              onEmailSendClick={onEmailSendClick}
              buttonDisabled={disableEmailSend}
              errorMessage={errors?.email}
              waitingTimeForNextCode={values.waitingTimeForNextCode}
            />
          ) : (
            <PassStep
              pass={values.authenticationCode}
              email={values.email}
              onPassChange={onPassChange}
              onPassSendClick={onPassSendClick}
              onBackButtonClick={onBackButtonClick}
              onHelpButtonClick={onHelpButtonClick}
              buttonDisabled={disablePassSend}
              timeLeft={values.time}
              errorMessage={errors?.authenticationCode}
              onEmailSendClick={onEmailSendClick}
              isOutOfTime={isOutOfTime}
              isOutOfAttempts={isOutOfAttempts}
              isServiceUnavailable={isServiceUnavailable}
              isOpenInfo={isOpenInfo}
              waitingTimeForNextCode={values.waitingTimeForNextCode}
            />
          )}
        </Content>

        <SupportWrapper>
          {isDemoLoading ? (
            <LoadingIndicatorWrapper>
              <LoadingIndicator />
            </LoadingIndicatorWrapper>
          ) : (
            <>
              {false && (
                <SupportTextWrapper onClick={onSupportClick}>
                  <SupportIcon name={IconNames.Support} />
                  <SupportText>{t('login.demo')}</SupportText>
                </SupportTextWrapper>
              )}
              <SignUpTextWrapper onClick={handleSignUp}>
                <SignUpText>{t('login.signUp')}</SignUpText>
              </SignUpTextWrapper>
            </>
          )}
        </SupportWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
  width: 335px;
  border: 1px solid ${Colors.gray};
  border-radius: 24px;
  box-shadow: 0px 40px 80px #c0c6cb;
  background-color: ${Colors.white};

  ${tablet} {
    width: 432px;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: -1px;
  left: 20px;
  padding: 36px 10px 24px;
  border-radius: 0 0 8px 8px;
  background-color: ${Colors.black};

  ${tablet} {
    left: 55px;
    padding: 48px 12px 32px;
  }

  ${() =>
    isLearnITTheme() &&
    css`
      top: 58px;
      padding: 0;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      ${tablet} {
        padding: 0;
        top: 67px;
      }
    `}
`;

const Logo = styled(Icon)`
  height: 38px;
  width: 68px;

  ${tablet} {
    height: 48px;
    width: 78px;
  }

  ${() =>
    isLearnITTheme() &&
    css`
      height: 28px;
      width: 160px;
      ${tablet} {
        height: 28px;
        width: 178px;
      }
    `}
`;

const Content = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 128px 20px 30px;

  ${tablet} {
    padding: 160px 55px 34px;
  }
`;

const SupportWrapper = styled.div`
  position: absolute;
  top: calc(100% + 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SupportTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: 0.5s;
  }
`;

const SignUpTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0.3;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    opacity: 1;
    transition: 0.5s;
  }
`;

const SignUpText = styled.span`
  margin-left: 5px;
  ${fontService.text({ type: TextType.normal })};
  color: ${Colors.black};
`;

const SupportIcon = styled(Icon)`
  height: 20px;
  width: 20px;
`;

const SupportText = styled.span`
  margin-left: 5px;
  ${fontService.text({ type: TextType.normal })};
  color: ${Colors.black};
`;

const LoadingIndicatorWrapper = styled.div`
  height: 25px;
  width: 25px;
`;
